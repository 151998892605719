import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { ButtonGroupStoryblok } from "../../../component-types-sb"
import LinkButton from "../general/LinkButton"
import Container from "../general/Container"
import CallButton from "../general/CallButton"
import DemoButton from "../general/DemoButton"
import TrailButton from "../general/TrailButton"

interface Props {
  readonly blok: ButtonGroupStoryblok
}

const ButtonContainer = tw(Container)`
  [&[data-align-button-container="left"]]:(
    items-start
    px-0
  )
`

const ButtonGroupWrapper = tw.div`
  flex flex-wrap justify-center
  gap-6 laptop:gap-10
  my-4
  tablet:w-auto
  w-full
`

export const ButtonGroup: React.FC<Props> = ({ blok }) =>
<SbEditable content={blok}>
  <ButtonContainer data-align-button-container={blok.alignment}>
    <ButtonGroupWrapper>
      {blok.buttons?.map((button) => {
        if (button.component === "button")
          return <LinkButton key={button._uid} button={button} />

        switch (button.component) {
          case "callButton":
            return <CallButton blok={button} key={button._uid} />
          case "demoButton":
            return <DemoButton blok={button} key={button._uid} />
          case "trailButton":
            return <TrailButton blok={button} key={button._uid} />
          default:
            return <span>Button not found</span>
        }
      })}
    </ButtonGroupWrapper>
  </ButtonContainer>
</SbEditable>

export default ButtonGroup
