import { Link } from "gatsby"
import React, { Suspense, useState } from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/pro-solid-svg-icons"
import { Menu } from "@headlessui/react"
import useLocale from "../../../hooks/useLocale"
import LinkButton from "../../general/LinkButton"
import { useMobileHeader } from "../../../contexts/MobileHeaderContext"
import LinkResolver from "../../general/Link"
import Image from "../../general/Image"
import useTranslatedLinks from "../../../contexts/TranslatedLinksContext"
import { changePreferredLang } from "../../../utils/changePreferredLang"
import locales from "../../../data/locales"
import ClientOnly from "../../general/ClientOnly"
import useRegion from "../../../hooks/useRegion"
import HeaderDropdown from "./HeaderDropdown"

const MobileMenu = React.lazy(() => import("./MobileMenu"))

const HamburgerIcon = styled.div<{ open: boolean }>(({ open }) => [
  tw`w-5 h-0.5 rounded bg-gray-2 transform transition-all duration-500 ease-in-out`,
  tw`before:(
    absolute left-0
    content-[""]
    transform -translate-y-2
    w-5 h-0.5
    rounded
    bg-gray-2
    transition-transform duration-500 ease-in-out
  )`,
  tw`after:(
    absolute left-0
    content-[""]
    transform translate-y-2
    w-5 h-0.5
    rounded
    bg-gray-2
    transition-transform duration-500 ease-in-out
  )`,
  open && tw`bg-transparent -translate-x-14`,
  open && tw`before:(translate-x-14 rotate-45 translate-y-0)`,
  open && tw`after:(translate-x-14 -rotate-45 translate-y-0)`,
])

// @ts-expect-error (this needs to be ignored for now)
const LanguageMenuItem = styled(Link)<{ isactive: string }>(({ isactive }) => [
  tw`px-6 text-center text-16`,
  isactive === "true" && tw`text-blue`,
])

const StickyMenu = tw.div`
  sticky top-0
  z-30
  w-full
  h-[70px]
  flex justify-between space-x-4 items-center
  shadow
  bg-white
  desktop:px-6
  desktop-large:px-8
  desktop-xl:px-24
  before:(
    content-[""]
    h-1
    absolute -top-1 inset-x-0
    bg-white
  )
`

const convertLocale = (locale: string) => {
  if (locale === "default") return "EN"
  return locale.toUpperCase()
}

const Header: React.FC = () => {
  const { content, openMain, setOpenMain } = useMobileHeader()
  const { preferredLang } = useLocale()
  const [subMenuOpen, setSubMenuOpen] = useState(-1)
  const translatedLinks = useTranslatedLinks()
  const location = useRegion()
  // eslint-disable-next-line new-cap
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return (
    <SbEditable content={content}>
      {/* data-location can be used for debugging localized content in production */}
      <StickyMenu data-location={location} data-timezone={timezone}>
        <Link tw="flex-shrink-0 ml-4 tablet:ml-6 desktop:ml-0" to={`/${preferredLang.replace("default", "")}`}>
          <Image image={content.logo} width="200px" fluid={200} lazy={false} showPlaceholder={false} useRegularImageTag />
        </Link>
        <nav tw="hidden desktop:flex items-stretch h-full">
          {content.sections.map((section, index) => {
            if (section.component === "link")
              return <div
                key={section._uid}
                tw="text-17 text-center text-gray-1 flex items-center h-full px-3 desktop-large:px-5"
              >
                <LinkResolver url={section.url} styling="highlight">
                  {section.text}
                </LinkResolver>
              </div>
            return <HeaderDropdown
              key={section._uid}
              section={section}
              open={subMenuOpen === index}
              openMenu={() => setSubMenuOpen(index)}
              closeMenu={() => setSubMenuOpen(-1)}
            />
          })}
        </nav>
        <div tw="hidden h-full desktop:flex items-center space-x-4">
          {content.login_link.length > 0 && <LinkResolver tw="text-gray-1" url={content.login_link[0].url} styling="highlight">
            <div tw="text-17 flex items-center h-full ">
              {content.login_link[0].text}
            </div>
          </LinkResolver>}
          {content.free_trial_button.length > 0 && (
            <LinkButton
              tw="rounded-none h-full desktop-xl:(rounded-full h-auto)"
              button={content.free_trial_button[0]}
            />
          )}
          <Menu>
            <div tw="relative">
              <Menu.Button tw="flex items-center hocus:(text-blue) focus:outline-none focus-visible:outline-black">
                <FontAwesomeIcon icon={faGlobe} tw="mr-2" />
                <span>{convertLocale(preferredLang)}</span>
              </Menu.Button>
              <Menu.Items
                tw="absolute top-full -right-8 z-50 flex flex-col bg-white space-y-2
                  shadow items-center mt-2 py-4 mr-4 rounded w-fit
                  focus:outline-none focus-visible:outline-black"
              >
                {locales.filter((lang) => lang.locale !== preferredLang).map(({ locale }) => <Menu.Item key={locale}>
                  {({ active }) => <LanguageMenuItem
                    isactive={active
                      ? "true"
                      : "false"}
                    to={translatedLinks[locale]}
                    onClick={() => changePreferredLang(locale)}
                  >
                    {convertLocale(locale)}
                  </LanguageMenuItem>
                  }
                </Menu.Item>)}
              </Menu.Items>
            </div>
          </Menu>
        </div>
        <div tw="block desktop:hidden">
          <button
            tw="flex items-center justify-center h-[70px] px-4 tablet:px-6 text-24 relative"
            onClick={() => setOpenMain(true)}
            aria-label="toggle-menu"
          >
            <HamburgerIcon open={openMain} />
          </button>
          <ClientOnly>
            <Suspense fallback={<div />}>
              <MobileMenu open={openMain} />
            </Suspense>
          </ClientOnly>
        </div>
      </StickyMenu>
    </SbEditable>
  )
}

export default Header
