import React from "react"
import SbEditable from "storyblok-react"
import Flickity from "react-flickity-component"
import { CarouselStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"

import "flickity/css/flickity.css"

interface Props {
  readonly blok: CarouselStoryblok
}

const Carousel: React.FC<Props> = ({ blok }) =>
<SbEditable content={blok}>
  <div tw="w-full">
    <Flickity
      options={{
        autoPlay: 3000,
        initialIndex: 1,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true,
      }}
    >
      {blok.images.map((image) => <div key={image.id} tw="w-[96vw] laptop:w-screen-1/2 ">
        <Image image={image} />
      </div>)}
    </Flickity>
  </div>
</SbEditable>

// Use this for blog
export const CarouselBlog: React.FC<Props> = ({ blok }) =>
<SbEditable content={blok}>
  <div tw="max-w-container w-full my-8">
    <Carousel blok={blok} />
  </div>
</SbEditable>

export default Carousel
