import React from "react"
import SbEditable from "storyblok-react"
import { FeatureListStoryblok } from "../../../component-types-sb"
import Feature from "../general/Feature"
import { H4 } from "../typography/Typography"

interface Props {
  readonly blok: FeatureListStoryblok
}

const FeatureList: React.FC<Props> = ({ blok }) =>
<SbEditable content={blok}>
  <div tw="flex-1 mb-1">
    {blok.title && <H4 tw="my-8">{blok.title}</H4>}
    <ul tw="space-y-1 w-fit text-18">
      {blok.features.map((feature) => <Feature key={feature._uid} blok={feature} />)}
    </ul>
  </div>
</SbEditable>

export default FeatureList
