import { faStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { InlineReviewStoryblok, ReviewCompanyStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import { P } from "../typography/Typography"

const Wrapper = tw(P)`
  my-4
`

const StarIcon = tw(FontAwesomeIcon)`
  text-yellow align-text-top
`

const Rating = tw.span`
  ml-2
`

const SmallText = tw.span`
  mr-2 text-gray-3
  [.section-dark-background &]:text-white
`

interface Props {
  readonly blok: InlineReviewStoryblok
}

const InlineReview: React.FC<Props> = ({ blok }) => {
  if (!blok.review_company) return null

  const { content } = blok.review_company as unknown as { content: ReviewCompanyStoryblok }

  const { logo, rating } = content ?? {}

  return <SbEditable content={blok}>
    <Wrapper>
      {[...Array(5).keys()].map((i) => (
        <StarIcon key={i} icon={faStar} />
      ))}
      <Rating>{rating}/5&nbsp;</Rating>
      <SmallText>{blok.rated_on_text}</SmallText>
      <Image tw="object-contain h-5 inline-block align-text-top" fit="contain" image={logo} />
    </Wrapper>
  </SbEditable>
}

export default InlineReview
