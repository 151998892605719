import React from "react"
import { Helmet } from "react-helmet-async"
import SbEditable from "storyblok-react"
import { CalendlyEmbedStoryblok } from "../../../component-types-sb"
import "twin.macro"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import useLocale from "../../hooks/useLocale"

interface Props {
  readonly blok: CalendlyEmbedStoryblok
}

const styles = {
  large: { height: "750px", minWidth: "100%" },
  small: { height: "630px", minWidth: "350px" },
}

const CalendlyEmbed: React.FC<Props> = ({ blok }) => {
  // eslint-disable-next-line new-cap
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const isAmericas = (/America/u).exec(tz)
  const { preferredLang } = useLocale()
  const { calendly_americas_url, calendly_default_url, calendly_de_url, calendly_nl_url } = useGlobalSettings()

  if (!calendly_default_url) return <p tw="text-center text-30 text-red">No default Calendly url</p>

  const localeUrls = {
    de: calendly_de_url,
    default: calendly_default_url,
    nl: calendly_nl_url,
  }

  let url = blok.calendly_url

  if (!url)
    url = (isAmericas && calendly_americas_url)
      ? calendly_americas_url
      : (localeUrls[preferredLang] ?? calendly_default_url)

  return (
    <SbEditable content={blok}>
      <Helmet>
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async />
      </Helmet>

      <div
        className="calendly-inline-widget"
        data-url={`${url}?hide_landing_page_details=1&hide_gdpr_banner=1&hide_cookie_banner=1`}
        style={styles[blok.size ?? "small"]}
      />
    </SbEditable>
  )
}

export default CalendlyEmbed
