import React from "react"
import SbEditable from "storyblok-react"
import { CallButtonStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import LinkButton from "./LinkButton"

interface Props {
  blok: CallButtonStoryblok
}

const CallButton: React.FC<Props> = ({ blok }) => {
  const { call_button } = useGlobalSettings()

  if (!call_button || call_button.length !== 1)
    return <span>Call button not found, update it in global settings</span>

  return (
    <SbEditable content={blok}>
      <LinkButton button={call_button[0]} />
    </SbEditable>
  )
}

export default CallButton
