import { library, config } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/pro-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { far } from "@fortawesome/pro-regular-svg-icons"
import { fal } from "@fortawesome/pro-light-svg-icons"
import { fad } from "@fortawesome/pro-duotone-svg-icons"
import "@fortawesome/fontawesome-svg-core/styles.css"

// Prevent FontAwesome from adding its CSS automatically
config.autoAddCss = false

library.add(fas, fab, far, fal, fad)
