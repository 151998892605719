import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useRef, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import tw, { styled } from "twin.macro"
import { useWindowSize } from "../../hooks/useWindowSize"

interface TooltipProps {
  tooltip: string
  children?: React.ReactNode
  comparisonInfo?: boolean
  noMargin?: boolean
}

const TooltipContainer = styled.div<{ noMargin?: boolean }>`
  ${tw`relative inline-flex items-center cursor-pointer focus:outline-none`}
  ${({ noMargin }) => !noMargin && tw`ml-1.5`}
`

const TooltipContent = styled.div`
  ${tw`
    absolute z-10 flex flex-col items-center px-4 py-2 font-normal text-black bg-white
    border rounded-lg shadow-xl text-14 mb-11 max-w-[18rem] border-gray-4
  `}
  transform: translate(-50%, -100%);
`

const SpeechTail = styled.div<{ comparisonInfo?: boolean }>`
  ${tw`absolute w-3 h-3 bg-white border-b border-r border-gray-4`}
  transform: rotate(45deg) translate(-50%, 55%);
  bottom: -6px;
  left: 51%;
  ${({ comparisonInfo }) => comparisonInfo && tw`left-[52%]`}
`

const Tooltip: React.FC<TooltipProps> = ({ tooltip, children, noMargin, comparisonInfo }) => {
  const [visible, setVisible] = useState(false)
  const [coords, setCoords] = useState({ left: 0, top: 0 })
  const ref = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const { width: windowWidth } = useWindowSize()

  useEffect(() => {
    if (visible && ref.current && tooltipRef.current) {
      const rect = ref.current.getBoundingClientRect()
      const tooltipRect = tooltipRef.current.getBoundingClientRect()
      const viewportWidth = window.innerWidth
      let left = rect.left + rect.width / 2 + window.scrollX
      let top = rect.top + window.scrollY + tooltipRect.height - 38
      // Adjust if tooltip overflows the viewport
      if (left - tooltipRect.width / 2 < 0)
        left = tooltipRect.width / 2 + window.scrollX
       else if (left + tooltipRect.width / 2 > viewportWidth)
        left = viewportWidth - tooltipRect.width / 2 + window.scrollX
      if (top - tooltipRect.height < 0)
        top = rect.bottom + tooltipRect.height + window.scrollY
      setCoords({ left, top })
    }
  }, [visible, windowWidth])

  return (
    <TooltipContainer
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      onFocus={() => setVisible(true)}
      onBlur={() => setVisible(false)}
      ref={ref}
      tabIndex={0}
      noMargin={noMargin}
    >
      <div ref={tooltipRef}>
        {children ?? <FontAwesomeIcon tw="text-gray-4" icon={faInfoCircle} />}
      </div>
      {visible && (
        <>
          {ReactDOM.createPortal(
            <TooltipContent style={{ left: coords.left, top: coords.top }}>
              {tooltip}
              <SpeechTail
                comparisonInfo={comparisonInfo}
              />
            </TooltipContent>,
            document.body,
          )}
        </>
      )}
    </TooltipContainer>
  )
}

export default Tooltip
