import React, { createContext, useCallback, useContext, useMemo, useState } from "react"
import { rewriteSlug } from "../utils/rewriteSlug"
import { ChildrenProp } from "../../react"
import { useSitePages } from "./SitePagesContext"

interface Links {
  default: string
  nl: string
  de: string
  es: string
}

interface TranslatedLinks extends Links {
  changeTranslatedLinks: (_story: Story) => void
}

export const TranslatedLinksContext = createContext<TranslatedLinks | null>(null)

export const TranslatedLinksProvider: React.FC<ChildrenProp> = ({ children }) => {
  const [links, setLinks] = useState<Links>({
    de: "/de",
    default: "/",
    es: "/es",
    nl: "/nl",
  })

  const { isSitePage } = useSitePages()

  const changeTranslatedLinks = useCallback((story: Story) => {
    const newLinks = {
      de: rewriteSlug(story.default_full_slug),
      default: rewriteSlug(story.default_full_slug),
      es: rewriteSlug(story.default_full_slug),
      nl: rewriteSlug(story.default_full_slug),
    }
    story.translated_slugs.forEach(({ path, lang }) => {
    // @ts-expect-error (this needs to be ignored for now)
      if (isSitePage(`${lang}/${path}`)) newLinks[lang] = rewriteSlug(`${lang}/${path}`)
    })
    setLinks(newLinks)
  }, [isSitePage])

  const contextValue = useMemo(() => ({
    ...links,
    changeTranslatedLinks,
  }), [changeTranslatedLinks, links])

  return <TranslatedLinksContext.Provider value={contextValue}>
    {children}
  </TranslatedLinksContext.Provider>
}

interface Story {
  default_full_slug: string
  translated_slugs: {
    path: string
    lang: string
  }[]
}

const useTranslatedLinks = (): TranslatedLinks => {
  const context = useContext(TranslatedLinksContext)
  if (!context) throw new Error("setTranslatedLinks must be used within a TranslatedLinksProvider")
  return context
}

export default useTranslatedLinks
