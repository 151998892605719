import { graphql, useStaticQuery } from "gatsby";
import React, {
  useState,
  createContext,
  useContext,
  useRef,
  useMemo,
} from "react";
import { useLocation } from "@reach/router";
import useLocale from "../hooks/useLocale";
import useStoryblok from "../hooks/useStoryblok";
import { HeaderStoryblok } from "../../component-types-sb";
import { ChildrenProp } from "../../react";

interface Context {
  content: HeaderStoryblok;
  openMain: boolean;
  setOpenMain: React.Dispatch<React.SetStateAction<boolean>>;
  closeAll: () => void;
  closeSubmenuRef: React.MutableRefObject<() => void>;
}

interface HeaderData {
  stories: {
    nodes: {
      uuid: string;
      lang: string;
      content: string;
      field_component: string;
    }[];
  };
}

export const MobileHeaderContext = createContext<Context | null>(null);

export const MobileHeaderContextProvider: React.FC<ChildrenProp> = ({
  children,
}) => {
  const data: HeaderData = useStaticQuery(graphql`
    query HeaderQuery {
      stories: allStoryblokEntry(
        filter: { field_component: { eq: "header" } }
      ) {
        nodes {
          uuid
          lang
          content
          field_component
        }
      }
    }
  `);
  const { preferredLang } = useLocale();
  // eslint-disable-next-line typescript-eslint/no-non-null-assertion
  const story = data.stories.nodes.find((x) => x.lang === preferredLang)!;
  const location = useLocation();
  const { content } = useStoryblok(story, location) as {
    content: HeaderStoryblok;
  };

  const [openMain, setOpenMain] = useState(false);
  // eslint-disable-next-line typescript-eslint/no-empty-function
  const closeSubmenuRef = useRef<() => void>(() => {});
  const closeAll = () => {
    if (closeSubmenuRef.current) closeSubmenuRef.current();
    setOpenMain(false);
  };

  const contextValue = useMemo(
    () => ({
      closeAll,
      closeSubmenuRef,
      content,
      openMain,
      setOpenMain,
    }),
    [content, openMain]
  );

  return (
    <MobileHeaderContext.Provider value={contextValue}>
      {children}
    </MobileHeaderContext.Provider>
  );
};

export const useMobileHeader = (): Context => {
  const context = useContext(MobileHeaderContext);
  if (!context)
    throw new Error(
      "useMobileHeader must be used within a MobileHeaderProvider"
    );
  return context;
};
