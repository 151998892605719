import React from "react"
import SbEditable from "storyblok-react"
import { DemoButtonStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import LinkButton from "./LinkButton"

interface Props {
  blok: DemoButtonStoryblok
}

const DemoButton: React.FC<Props> = ({ blok }) => {
  const { demo_button } = useGlobalSettings()

  if (!demo_button || demo_button.length !== 1)
    return <span>Demo button not found, update it in global settings</span>

  return (
    <SbEditable content={blok}>
      <LinkButton button={demo_button[0]} />
    </SbEditable>
  )
}

export default DemoButton
