import StoryblokClient from "storyblok-js-client"
import { rewriteSlug } from "../utils/rewriteSlug"

interface SiteMapQuery {
  allSitePage: {
    nodes: {
      path: string
    }[]
  }
  allStoryblokEntry: {
    nodes: {
      full_slug: string
      published_at: string
      default_full_slug: string
      translated_slugs: {
        path: string
        lang: string
      }[]
    }[]
  }
}

interface ResolvedPages {
  path: string
  published_at: string
  links: {
    lang: string
    url: string
  }[]
}

const siteUrl = "https://www.webinargeek.com"

const Storyblok = new StoryblokClient({
  accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
})

const config = {
  plugins: [
    "gatsby-plugin-react-helmet-async",
    "gatsby-plugin-styled-components",
    {
      options: {
        gtmAuth: "SMyeOqdz5BmHd372rwVCVg",
        gtmPreview: "env-3",
        id: "GTM-5K5PLJT",
        includeInDevelopment: true,
      },
      resolve: "gatsby-plugin-google-tagmanager",
    },
    {
      options: {
        host: siteUrl,
        policy: [{ allow: "/", userAgent: "*" }],
        sitemap: `${siteUrl}/sitemap/sitemap.xml`,
      },
      resolve: "gatsby-plugin-robots-txt",
    },
    {
      options: {
        domains: [
          "https://googletagmanager.com",
          "https://img2.storyblok.com/",
          "https://a.storyblok.com/",
        ],
      },
      resolve: "gatsby-plugin-preconnect",
    },
    {
      options: {
        component: require.resolve("../components/layout/Layout.tsx"),
      },
      resolve: "gatsby-plugin-layout",
    },
    {
      options: {
        dsn: "https://605340e36986b42c3632cce6993e73e5@o4507763401424896.ingest.de.sentry.io/4507763454574672",
        environment: "production",
        release: process.env.GATSBY_VERCEL_GIT_COMMIT_SHA,
        sampleRate: 0.7,
      },
      resolve: "@sentry/gatsby",
    },
    {
      options: {
        output: "/sitemap",
        query: `
        {
          allSitePage(filter: {path : {nin: ["/app-information", "/nl/app-information", "/storyblok-documentation"]}}){
            nodes{
              path
            }
          }
          allStoryblokEntry{
            nodes{
              full_slug
              published_at
              default_full_slug
              translated_slugs{
                lang
                path
              }
            }
          }
        }
        `,
        resolvePages: ({
          allSitePage: { nodes: allPages },
          allStoryblokEntry: { nodes: allSbEntry },
        }: SiteMapQuery): ResolvedPages[] => {
          const sbNodeMap: Record<string, ResolvedPages> = allSbEntry.reduce((acc, node) => {
            const { full_slug, default_full_slug, translated_slugs, published_at } = node
            const slug = rewriteSlug(full_slug)
            const links = translated_slugs.map(({ path, lang }) => {
              const translatedSlug = rewriteSlug(`${lang}/${path}`)
              if (allPages.some(({ path: actualPath }) => translatedSlug === actualPath))
                return ({
                  lang,
                  url: translatedSlug,
                })

              return null
            }).filter((x) => x !== null)
            const newNode = {
              links: [
                { lang: "x-default", url: rewriteSlug(default_full_slug) },
                { lang: "en", url: rewriteSlug(default_full_slug) },
                ...links,
              ],
              published_at,
            }
            // @ts-expect-error (this needs to be ignored for now)
            acc[slug] = newNode
            return acc
          }, {})
          return allPages.map((page) => ({ ...sbNodeMap[page.path], ...page }))
        },
        resolveSiteUrl: (): string => siteUrl,
        serialize: ({ path, published_at, links }: ResolvedPages): {
          url: string
          lastmod: string
          links: { lang: string, url: string }[]
        } => ({
          lastmod: published_at,
          links,
          url: path,
        }),
      },
      resolve: "gatsby-plugin-sitemap",
    },
    {
      options: {
        background_color: "#ffffff",
        display: "standalone",
        icon: "src/images/logo.png",
        lang: "en",
        localize: [
          {
            lang: "nl",
            start_url: "/nl",
          },
          {
            lang: "de",
            start_url: "/de",
          },
        ],
        name: "WebinarGeek",
        short_name: "WebinarGeek",
        start_url: "/",
        theme_color: "#24A6C4",
      },
      resolve: "gatsby-plugin-manifest",
    },
    "gatsby-plugin-webpack-bundle-analyser-v2",
    "gatsby-plugin-vercel-deploy",
    {
      options: {
        accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
        includeLinks: true,
        resolveLinks: "url",
        resolveRelations: [
          "blogPost.author",
          "categoryPage.featured_post",
          "customerSuccessStoryCard.success_story",
          "personCard.person",
          "blogIndexPage.featured_stories",
          "blogIndexPage.categories",
          "academyCourse.chapters",
          "academyCourse.tags",
          "academyCard.course",
          "videoCard.video",
          "globalSettings.companies",
          "globalSettings.blog_categories",
          "globalSettings.all_courses",
          "globalSettings.css_stories_default",
          "globalSettings.css_stories_us",
          "globalSettings.css_stories_nordics",
          "globalSettings.css_stories_dach",
          "comparsionTable.companies",
          "comparisonCard.companies",
          "pageCard.page",
          "registerPage.quotes",
          "review.review_company",
          "quote.review",
          "reviewGrid.review_companies",
          "reviewCarousel.reviews",
          "allCustomerSuccessStories.success_stories",
          "customerSuccessStoriesIndex.success_stories",
          "customerSuccessStoriesHighlight.success_story",
          "customerSuccessStoriesList.success_stories",
          "customerSuccessStory.use_case",
          "customerSuccessStory.hidden_usecases",
          "inlineReview.review_company",
          "featuredBlogs.blogs",
          "background.tag",
          "virtualBackgroundsIndex.category",
        ],
        version: process.env.NODE_ENV === "development"
          ? "draft"
          : "published",
      },
      resolve: "gatsby-source-storyblok",
    },
    {
      options: {
        feeds: [
          {
            output: "/rss.xml",
            query: `
            {
              site {
                siteMetadata {
                  siteUrl
                }
              }
            }
            `,
            serialize: async ({ query: { site } }: { query: { site: { siteMetadata: { siteUrl: string } } } }) => {
              const { data } = await Storyblok.get("cdn/stories", {
                filter_query: {
                  component: {
                    in: "blogPost",
                  },
                },
                starts_with: "learn/",
                version: "published",
              })
              return data.stories
                // eslint-disable-next-line typescript-eslint/no-explicit-any
                .sort((a: any, b: any) => new Date(b.content.datePublished).getTime() - new Date(a.content.datePublished).getTime())
                .slice(0, 10)
                // eslint-disable-next-line typescript-eslint/no-explicit-any
                .map((story: any) => {
                  const blogPostSlug = story.full_slug.split("/").pop()
                  return {
                    custom_elements: [{ "content:encoded": story.content.content }],
                    date: new Date(story.content.datePublished).toUTCString(),
                    description: story.content.description,
                    enclosure: {
                      type: "image/jpeg",
                      url: story.content.coverImage.filename,
                    },
                    guid: `${site.siteMetadata.siteUrl}/learn/${blogPostSlug}`,
                    title: story.content.title,
                    url: `${site.siteMetadata.siteUrl}/learn/${blogPostSlug}`,
                  }
                })
            },
            title: "WebinarGeek RSS Feed",
          },
        ],
      },
      resolve: "gatsby-plugin-feed",
    },
  ],
  siteMetadata: {
    siteUrl,
  },
  trailingSlash: "never",
}

export default config
