import { useEffect, useState } from "react";
import StoryblokClient, { StoryData } from "storyblok-js-client";
import { WindowLocation } from "@reach/router";
import config from "../gatsby/gatsby-config";

export interface StoryblokComponent<TComp extends string> {
  _uid: string;
  component: TComp;
  _editable?: string;
}

declare global {
  interface StoryblokBridgeConfig {
    initOnlyOnce?: boolean;
    accessToken?: string;
  }
  interface StoryblokEventPayload<S extends StoryblokComponent<string>> {
    action:
      | "customEvent"
      | "published"
      | "input"
      | "change"
      | "unpublished"
      | "enterEditmode";
    event?: string;
    story?: StoryData<S>;
    slug?: string;
    slugChanged?: boolean;
    storyId?: number;
    reload?: boolean;
  }
  class StoryblokBridge {
    on: (
      _event:
        | "customEvent"
        | "published"
        | "input"
        | "change"
        | "unpublished"
        | "enterEditmode"
        | string[],
      _callback: (_payload?: StoryblokEventPayload) => void
    ) => void;

    constructor(_options: { resolveRelations: string[] });
  }
  interface Window {
    StoryblokBridge: typeof StoryblokBridge;
    StoryblokCacheVersion: number;
  }
}

interface SbConfig {
  resolve: string;
  options: {
    accessToken: string;
    version: string;
    resolveLinks: string;
    resolveRelations: string[];
  };
}

interface Story {
  content: string | unknown;
  lang: string;
  field_component: string;
}

export const sbConfig = config.plugins.find((item) => {
  if (typeof item !== "string")
    return item.resolve === "gatsby-source-storyblok";
  return false;
}) as SbConfig;

export const Storyblok = new StoryblokClient({
  accessToken: sbConfig.options.accessToken,
  cache: {
    clear: "auto",
    type: "memory",
  },
});

const useStoryblok = (
  originalStory: Story,
  location: WindowLocation
): Story => {
  const [story, setStory] = useState(originalStory);

  if (story && typeof story.content === "string")
    story.content = JSON.parse(story.content);
  // see https://www.storyblok.com/docs/Guides/storyblok-latest-js
  useEffect(() => {
    const initEventListeners = () => {
      const { StoryblokBridge } = window;

      if (typeof StoryblokBridge !== "undefined") {
        const storyblokInstance = new StoryblokBridge({
          resolveRelations: sbConfig.options.resolveRelations,
        });

        storyblokInstance.pingEditor(() => {
          const lang = /_storyblok_lang=(?<lang>\w+)/u.exec(location.search)
            ?.groups?.lang;
          const storyId = /_storyblok=(?<id>\w+)/u.exec(location.search)?.groups
            ?.id;
          if (!lang || !storyId) return;

          Storyblok.get(`cdn/stories/${storyId}`, {
            language: lang,
            resolve_links: "url",
            resolve_relations: sbConfig.options.resolveRelations.join(","),
            version: "draft",
          })
            .then(({ data }: { data: { story: Story } }) => {
              if (data.story) setStory(data.story);
            })
            .catch((error: unknown) => {
              console.error(error);
            });
        });

        storyblokInstance.on(["published", "change"], () => {
          // reload project on save an publish
          window.location.reload();
        });

        storyblokInstance.on(["input"], (event) => {
          // live updates when editing
          setStory(event?.story as unknown as Story);
        });
      }
    };

    const addBridge = (callback: () => void) => {
      // check if the script is already present
      const existingScript = document.getElementById("storyblokBridge");
      if (existingScript) {
        callback();
      } else {
        const script = document.createElement("script");
        script.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
        script.id = "storyblokBridge";
        document.body.appendChild(script);
        script.onload = callback;
      }
    };

    if (
      // Need to check the field component matches up with the page we are editing
      location.search.includes(`_storyblok_c=${story.field_component}`)
    )
      addBridge(initEventListeners);
  }, [location.search, story.field_component]);

  return story;
};

export default useStoryblok;
