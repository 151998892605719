import React from "react"
import { Helmet } from "react-helmet-async"
import SbEditable from "storyblok-react"
import { createGlobalStyle } from "styled-components"
import { WistiaEmbedStoryblok } from "../../../component-types-sb"
import Container from "./Container"

interface Props {
  readonly blok: WistiaEmbedStoryblok
}

interface Wistia {
  readonly url: string
}

const GlobalStyle = createGlobalStyle`
 .w-css-reset {
    border-radius: 8px !important;
 }
`

export const WistiaVideo: React.FC<Wistia> = ({ url }) => {
  if (!url) return <p tw="text-center text-30 text-red">No Wistia url</p>
  const match = (/medias\/\w+/u).exec(url)
  if (!match) return <p tw="text-center text-30 text-red">Invalid Wistia url</p>
  const id = match[0].slice(7)
  return <>
    <Helmet>
      <script src={`https://fast.wistia.com/embed/medias/${id}.jsonp`} async />
      <script src="//fast.wistia.com/assets/external/E-v1.js" async />
    </Helmet>
    {/* @ts-expect-error (this needs to be ignored for now) */}
    <GlobalStyle />
    <div
      id={`wistia-${id}`}
      tw="relative pt-[56.25%] rounded-lg overflow-hidden"
      className="wistia_responsive_padding"
    >
      <div tw="h-full absolute inset-0 w-full" className="wistia_responsive_wrapper">
        <div className={`wistia_embed wistia_async_${id} autoplay=false`} tw="relative h-full w-full">
          <div
            tw="absolute inset-0 h-full w-full overflow-hidden opacity-0 transition-opacity duration-200 "
            className="wistia_swatch"
          >
            <img tw="h-full w-full object-contain [filter:blur(5px)]" alt="" src={`https://fast.wistia.com/embed/medias/${id}/swatch`} />
          </div>
        </div>
      </div>
    </div>
  </>
}

// Use this one for blog as no container
export const WistiaEmbedBlog: React.FC<Props> = ({ blok }) =>
<SbEditable content={blok}>
  <div tw="max-w-container w-full my-8">
    <WistiaVideo url={blok.wistia_url} />
  </div>
</SbEditable>

const WistiaEmbed: React.FC<Props> = ({ blok }) =>
<SbEditable content={blok}>
  <Container>
    <div tw="max-w-container w-full my-8">
      <WistiaVideo url={blok.wistia_url} />
    </div>
  </Container>
</SbEditable>

export default WistiaEmbed
