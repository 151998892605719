import useIsTimezone, { Timezone } from "./useIsTimezone";

type Region = "us" | "nordics" | "dach";

const locations: Record<Region, Timezone | Timezone[]> = {
  dach: ["Europe/Berlin", "Europe/Vienna", "Europe/Zurich"],
  nordics: [
    "Europe/Copenhagen",
    "Europe/Stockholm",
    "Europe/Oslo",
    "Europe/Helsinki",
  ],
  us: /America/u,
};

interface UseRegion {
  (): Region | "default";
}

const useRegion: UseRegion = () => {
  const isTimezone = useIsTimezone();
  const keys = Object.keys(locations) as Region[];
  // eslint-disable-next-line typescript-eslint/no-non-null-assertion
  const region = keys.find((location) => isTimezone(locations[location]))!;
  return region ?? "default";
};

export default useRegion;
