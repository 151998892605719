import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useEffect } from "react";

import styled from "styled-components";
import tw from "twin.macro";
import { HeaderDropdownOrLinkStoryblok } from "../../../../component-types-sb";
import LinkButton from "../../general/LinkButton";
import LinkResolver from "../../general/Link";
import { H5 } from "../../typography/Typography";

interface Props {
  readonly section: HeaderDropdownOrLinkStoryblok;
  readonly open: boolean;
  readonly openMenu: () => void;
  readonly closeMenu: () => void;
}

const Wrapper = styled.div(() => [tw`h-full`]);

const MenuWrapper = styled.div<{ open: boolean }>(({ open }) => [
  tw`bg-white invisible absolute inset-x-0 shadow top-[70px]`,
  open && tw`visible`,
]);

const MenuButton = styled.button<{ open: boolean }>(({ open }) => [
  tw`relative flex items-center h-full px-6 cursor-pointer text-gray-1`,
  open && tw`text-blue`,
]);

const HeaderDropdown: React.FC<Props> = ({
  section,
  open,
  openMenu,
  closeMenu,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const openMenuRef = useRef(openMenu);
  const closeMenuRef = useRef(closeMenu);

  useEffect(() => {
    // eslint-disable-next-line typescript-eslint/no-non-null-assertion
    const node = ref.current!;
    const openMenuFunction = openMenuRef.current;
    const closeMenuFunction = closeMenuRef.current;
    const handleFocusOut = (event: FocusEvent) => {
      if (!node.contains(event.relatedTarget as Node)) closeMenuFunction();
    };

    node.addEventListener("mouseenter", openMenuFunction);
    node.addEventListener("mouseleave", closeMenuFunction);
    node.addEventListener("focusout", handleFocusOut);

    return () => {
      node.removeEventListener("mouseenter", openMenuFunction);
      node.removeEventListener("mouseleave", closeMenuFunction);
      node.removeEventListener("focusout", handleFocusOut);
    };
  }, []);
  return (
    <Wrapper ref={ref} className="group" tw="h-full">
      <MenuButton
        open={open}
        tw="flex items-center h-full cursor-pointer px-3 desktop-large:px-5 relative"
        onClick={open ? closeMenu : openMenu}
      >
        <div className="hover-tab" tw="text-17 flex-1">
          {section.name}
        </div>
        <div tw="mx-auto text-gray-2 absolute bottom-0 inset-x-0 text-[8px] desktop-large:(relative ml-2 mr-0 mt-1)">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </MenuButton>
      <MenuWrapper open={open} className="hover-menu">
        <div tw="flex justify-center [maxWidth:940px] w-full mx-auto pt-[42px] pb-[61px] px-8">
          <div tw="text-left pr-16 flex-1.2">
            <H5 tw="text-blue mt-0">{section.name}</H5>
            <p tw="mb-4 text-17">{section.short_text}</p>
            {section.button.length > 0 && (
              <LinkButton button={section.button[0]} onClick={closeMenu} />
            )}
          </div>
          <div tw="text-17 flex-1 font-medium space-y-2.5 text-gray-1">
            {section.main_links.map((link) => (
              <div key={link._uid}>
                <LinkResolver
                  url={link.url}
                  styling="highlight"
                  onClick={closeMenu}
                >
                  {link.text}
                </LinkResolver>
              </div>
            ))}
          </div>
          {[0, 1].map((i) => (
            <div key={i} tw="text-left flex-1">
              {section.categories.length > i && (
                <>
                  <p tw="text-14 mb-4 text-gray-3">
                    {section.categories[i].title.toUpperCase()}
                  </p>
                  <div tw="flex flex-col items-start space-y-2.5">
                    {section.categories[i].links.map((link) => (
                      <div key={link._uid} tw="text-17">
                        <LinkResolver
                          url={link.url}
                          styling="highlight"
                          onClick={closeMenu}
                        >
                          {link.text}
                        </LinkResolver>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </MenuWrapper>
    </Wrapper>
  );
};

export default HeaderDropdown;
