import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog, Transition } from "@headlessui/react"
import React, { Fragment } from "react"
import tw from "twin.macro"
import { H2, P } from "../typography/Typography"
import { ChildrenProp } from "../../../react"

const DialogBase = tw(Dialog)`
  fixed inset-0 z-40 flex flex-row items-start justify-center min-h-screen px-4
  [&[data-modal-text-align="left"]]:text-left
  [&[data-modal-text-align="center"]]:text-center
  [&[data-modal-text-align="right"]]:text-right
  [&[data-modal-v-centered="true"]]:items-center
`

const TransitionOverlay = tw(Transition.Child)`
  [&.enter]:duration-200 ease-in
  [&.enterFrom]:(opacity-0)
  [&.enterTo]:(opacity-100)
  [&.leave]:(duration-200 ease-in)
  [&.leaveFrom]:(opacity-100)
  [&.leaveTo]:(opacity-0)
`

const Overlay = tw(Dialog.Overlay)`
  fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity
`

const TransitionModal = tw(Transition.Child)`
  flex flex-col
  transform
  bg-white
  rounded-lg
  shadow-xl
  w-full max-w-container
  max-h-[calc(100vh_-_56px)]
  mx-auto
  my-8 p-6
  [&.enter]:(duration-300 ease-out)
  [&.enterFrom]:(opacity-0 translate-y-20 tablet:(translate-y-20 scale-95))
  [&.enterTo]:(translate-y-0 opacity-100 tablet:scale-100)
  [&.leave]:(duration-200 ease-in)
  [&.leaveFrom]:(translate-y-0 opacity-100 tablet:scale-100)
  [&.leaveTo]:(opacity-0 translate-y-20 tablet:(translate-y-20 scale-95))
`

const CloseButton = tw.button`
  absolute top-4 right-4 px-2 text-24 hocus:text-blue
`

interface Props {
  readonly open: boolean
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>
  readonly vCentered?: boolean
  readonly textAlign?: "left" | "center" | "right"
}

const ModalRoot: React.FC<ChildrenProp & Props> = ({
  children,
  open,
  setOpen,
  vCentered = false,
  textAlign = "left",
}) => (
  // @ts-expect-error (this needs to be ignored for now)
  <Transition.Root show={open} as={Fragment}>
    <DialogBase
      static
      onClose={setOpen}
      data-modal-text-align={textAlign}
      data-modal-v-centered={vCentered}
    >
      <TransitionOverlay
        enter="enter"
        enterFrom="enterFrom"
        enterTo="enterTo"
        leave="leave"
        leaveFrom="leaveFrom"
        leaveTo="leaveTo"
      >
        <Overlay />
      </TransitionOverlay>
      <TransitionModal
        enter="enter"
        enterFrom="enterFrom"
        enterTo="enterTo"
        leave="leave"
        leaveFrom="leaveFrom"
        leaveTo="leaveTo"
      >
        <CloseButton aria-label="close-search" onClick={() => setOpen(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        {children}
      </TransitionModal>
    </DialogBase>
  </Transition.Root>
)

const ModalTitle = tw(H2)`
  mb-2 mt-0
`

const Title: React.FC<ChildrenProp> = ({ children, ...rest }) => (
  <ModalTitle as={Dialog.Title} {...rest}>
    {children}
  </ModalTitle>
)

const ModalSubtitle = tw(P)`
  text-gray-3
`

const Subtitle: React.FC<ChildrenProp> = ({ children, ...rest }) => (
  <ModalSubtitle as={Dialog.Description} {...rest}>
    {children}
  </ModalSubtitle>
)

const Body = tw.div`
  flex-1 overflow-y-auto text-left
`

const Modal = Object.assign(ModalRoot, {
  Body,
  Subtitle,
  Title,
})

export default Modal
