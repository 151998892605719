import { faQuoteLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { render } from "storyblok-rich-text-react-renderer-ts"
import { Block, Mark, DocumentNode } from "@marvr/storyblok-rich-text-types"
import {
  AnchorStoryblok,
  BlogCtaStoryblok,
  HeadingAnchorStoryblok,
  ImageRowStoryblok,
  BlogImageStoryblok,
  WistiaEmbedStoryblok,
  YoutubeVideoStoryblok,
  CarouselStoryblok,
  NativeFormStoryblok,
  CalendlyEmbedStoryblok,
  NativeFormIframeStoryblok,
  CtaStoryblok,
  KeywordDefinitionStoryblok,
  BunnyEmbedStoryblok,
} from "../../../component-types-sb"
import { BlockQuote, Heading, P, H2, H3 } from "../typography/Typography"
import { WistiaEmbedBlog } from "../general/WistiaEmbed"
import { MarkLinkResolver } from "../general/Link"
import { OL, Ul } from "../typography/List"
import { YoutubeVideoBlog } from "../section/YoutubeVideo"
import CalendlyEmbed from "../section/CalendlyEmbed"
import { CarouselBlog } from "../section/Carousel"
import NativeForm from "../section/NativeForm"
import NativeFormIframe from "../section/NativeFormIframe"
import Cta from "../section/Cta"
import KeywordDefinition from "../general/keywordDefinition"
import BunnyEmbed from "../general/BunnyEmbed"
import ImageRow from "./ImageRow"
import HeadingAnchor from "./HeadingAnchor"
import BlogCta from "./BlogCta"
import Anchor from "./Anchor"
import BlogImage from "./BlogImage"

const DefaultResolver: React.FC<{ readonly content: DocumentNode }> = ({ content }) => (
  <div tw="text-16 font-normal font-sans w-full">
    {render(content, {
      blokResolvers: {
        anchor: (props: AnchorStoryblok) => <Anchor blok={props} />,
        blogCta: (props: BlogCtaStoryblok) => <BlogCta blok={props} />,
        blogImage: (props: BlogImageStoryblok) => <BlogImage blok={props} />,
        bunnyEmbed: (props: BunnyEmbedStoryblok) => <BunnyEmbed blok={props} />,
        calendlyEmbed: (props: CalendlyEmbedStoryblok) => <CalendlyEmbed blok={props} />,
        carousel: (props: CarouselStoryblok) => <CarouselBlog blok={props} />,
        cta: (props: CtaStoryblok) => <Cta blok={props} />,
        headingAnchor: (props: HeadingAnchorStoryblok) => <HeadingAnchor blok={props} />,
        imageRow: (props: ImageRowStoryblok) => <ImageRow blok={props} />,
        keywordDefinition: (props: KeywordDefinitionStoryblok) => <KeywordDefinition blok={props} />,
        nativeForm: (props: NativeFormStoryblok) => <NativeForm blok={props} />,
        nativeFormIframe: (props: NativeFormIframeStoryblok) => <NativeFormIframe blok={props} />,
        wistiaEmbed: (props: WistiaEmbedStoryblok) => <WistiaEmbedBlog blok={props} />,
        youtubeVideo: (props: YoutubeVideoStoryblok) => <YoutubeVideoBlog blok={props} />,
      },
      defaultBlokResolver: (name, _) => <div>
        <code tw="text-red text-20">
          Missing blok resolver for blok type &quot;<span tw="font-bold">{name}</span>&quot;.
        </code>
      </div>,
      markResolvers: {
        [Mark.LINK]: (children, url) => <MarkLinkResolver url={url}>{children}</MarkLinkResolver>,
      },
      nodeResolvers: {
        [Block.QUOTE]: (children) => <BlockQuote tw="max-w-blog-quote mx-auto font-normal! text-white text-center not-italic p-8 my-8 bg-blue-prussian rounded-xl">
          <FontAwesomeIcon icon={faQuoteLeft} tw="text-orange text-30" />
          {children}
        </BlockQuote>,
        // @ts-expect-error (this needs to be ignored for now)
        [Block.UL_LIST]: (children) => <Ul tw="max-w-blog-text mx-auto">{children}</Ul>,
        [Block.HEADING]: (children, { level }) => {
          // @ts-expect-error (this needs to be ignored for now)
          if (level === 2 && children) return <H2 id={children[0]} tw="max-w-blog-text mx-auto text-34">{children}</H2>
          if (level === 3 && children) return <H3 tw="max-w-blog-text mx-auto text-26">{children}</H3>
          return <Heading tw="max-w-blog-text mx-auto" level={level}>{children}</Heading>
        },
        [Block.HR]: () => <hr tw="mb-6 text-gray-5" />,
        [Block.IMAGE]: (_) => <span tw="text-red font-bold text-20">Please use SingleImage component instead</span>,
        // @ts-expect-error (this needs to be ignored for now)
        [Block.OL_LIST]: (children) => <OL tw="max-w-blog-text mx-auto">{children}</OL>,
        [Block.PARAGRAPH]: (children) => <P tw="max-w-blog-text mx-auto leading-8">{children}</P>,
      },
    })}
  </div>
)

export default DefaultResolver
