import React from "react"
import { Image as ResponsiveImage, ImageProps } from "@storyofams/storyblok-toolkit"
import { DotLottieReact } from "@lottiefiles/dotlottie-react"

export interface ImageType {
  alt?: string
  filename: string
  title?: string
}

interface Props extends ImageProps {
  readonly image?: ImageType
  readonly useRegularImageTag?: boolean
}

const Image: React.FC<Props> = ({
  image, lazy = true, showPlaceholder, fluid, useRegularImageTag, ...rest
}) => {
  if (!image?.filename) return null

  // if lottie return null
  // if svg return normal image
  if (image.filename?.endsWith("svg") || useRegularImageTag)
    return <img
      className="storyblok-image-wrapper"
      src={image.filename}
      alt={image.alt}
      loading={lazy
        ? "lazy"
        : "eager"}
      {...rest}
    />
  // Use intrinsic width for fluid if not defined
  const initWidth = parseInt(((/(?<width>\d*)x\d*/u).exec(image.filename))?.groups?.width ?? "0", 10)

  if (image.filename?.endsWith("lottie"))
    // @ts-expect-error (this needs to be ignored for now)
    return <DotLottieReact src={image.filename} loop autoplay autoResizeCanvas tw="h-full w-full" />

  // Use responsive image if any other filetype
  return <ResponsiveImage
    src={image.filename}
    alt={image.alt}
    lazy={lazy}
    showPlaceholder={showPlaceholder}
    fluid={fluid ?? initWidth}
    {...rest}
  />
}

export default Image
