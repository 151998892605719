import tw from "twin.macro"
import Tag from "./Tag"

const TagFilterButton = tw(Tag)`
  bg-opacity-0
  text-black
  ring ring-1 ring-gray-3 ring-inset
  px-4 py-3
  min-w-[60px]
  text-center
  laptop:(px-2 py-1.5)
  statelayer before:bg-secondary-container-on
  peer-focus-visible:(
    outline-black
  )
  peer-checked:(
    bg-[#90C4E0]
    text-white
    ring-0
  )
  [[data-no-selected-items="true"] &]:(
    bg-[#90C4E0]
    text-white
    ring-0
  )
  [&[data-is-letter="true"]]:(
    min-w-[unset]
  )
`
export default TagFilterButton
