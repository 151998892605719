import React, { ComponentProps, forwardRef } from "react"
import tw from "twin.macro"

export const Input = tw.input`
  p-2
  rounded
  border border-gray-4
  placeholder-gray-4
  [&[data-invalid="true"]]:(
    border-red focus:border-red
  )
  focus:(
    outline-none
    border-blue border-2
    pl-[7px] pt-[7px] pb-[7px]
  )
  [[data-fancy-input-error="true"] &]:(
    border-red focus:border-red
  )
  [&[data-fancy-input-error="true"]]:(
    border-red focus:border-red
  )
`

const FancyInputWrapper = tw.div`
  relative
  flex-1
`

const InputComponent = tw(Input)`
  w-full
  px-3 py-2
  placeholder-transparent
  focus:(
    outline-none
    border-2 border-blue
    pt-[7px] pb-[7px] pl-[11px]
  )
`

const MovingLabel = tw.label`
  absolute top-2 left-2
  px-1
  text-gray-4 bg-white
  scale-75 -translate-y-5 origin-left
  transition
  cursor-text
  [[data-fancy-input-error="true"] &]:(
    text-red
  )
  peer-placeholder-shown:(
    bg-transparent
    scale-100 translate-y-0
    text-gray-4
  )
  peer-focus:(
    scale-75 -translate-y-5
    text-blue bg-white
    [[data-fancy-input-error="true"] &]:(
      text-red
    )
  )
`

const ErrorLabel = tw.p`
  text-red text-11 italic
`

export const TextAreaComponent = tw(Input)`
  placeholder-gray-4
  text-gray-1
  [&[data-fancy-input-error="true"]]:(
    border-red focus:border-red
  )
`

interface TextAreaProps extends ComponentProps<"input"> {
  error?: boolean
}

export const TextArea = ({ children, error, ...rest }: TextAreaProps) => (
  <TextAreaComponent
    as="textarea"
    data-fancy-input-error={Boolean(error)}
    {...rest}
  >
    {children}
  </TextAreaComponent>
)

type FancyInputProps = ComponentProps<"input"> & {
  error?: string | boolean
}

type InputWithErrorOutlineProps = ComponentProps<"input"> & {
  error?: boolean
}

export const InputWithErrorOutline = forwardRef<
  HTMLInputElement,
  InputWithErrorOutlineProps
>(({ error, ...rest }, ref) => (
  <FancyInputWrapper data-fancy-input-error={error}>
    <Input className="peer" ref={ref} tw="border-gray-5" {...rest} />
  </FancyInputWrapper>
))
InputWithErrorOutline.displayName = "InputWithErrorOutline"

/**
 * If passing a custom tw prop, add `className="peer"` to the component as styled components overwrites it.
 */
export const FancyInput = forwardRef<HTMLInputElement, FancyInputProps>(
  ({ id, placeholder, error, ...rest }, ref) => (
    <FancyInputWrapper data-fancy-input-error={Boolean(error)}>
      <InputComponent
        className="peer"
        id={id}
        ref={ref}
        placeholder={placeholder}
        {...rest}
      />
      <MovingLabel htmlFor={id}>{placeholder}</MovingLabel>
      {Boolean(error) && <ErrorLabel>{typeof error === "string"
        ? error
        : ""}</ErrorLabel>}
    </FancyInputWrapper>
  ),
)
FancyInput.displayName = "FancyInput"

/**
 * If passing a custom tw prop, add `className="peer"` to the component as styled components overwrites it.
 */
export const FancyTextArea = forwardRef<HTMLInputElement, FancyInputProps>(
  ({ id, placeholder, error, ...rest }, ref) => (
    <FancyInputWrapper data-fancy-input-error={Boolean(error)}>
      <InputComponent
        data-fancy-input-error={Boolean(error)}
        className="peer"
        id={id}
        ref={ref}
        placeholder={placeholder}
        as="textarea"
        {...rest}
      />
      <MovingLabel htmlFor={id}>{placeholder}</MovingLabel>
      {Boolean(error) && <ErrorLabel>{error}</ErrorLabel>}
    </FancyInputWrapper>
  ),
)
FancyTextArea.displayName = "FancyTextArea"
