import React from "react"
import SbEditable from "storyblok-react"
import tw, { TwStyle, styled } from "twin.macro"
import { CtaStoryblok } from "../../../component-types-sb"
import { H2, P } from "../typography/Typography"
import { isUndefined } from "../../utils/typeGuards"
import ButtonGroup from "./ButtonGroup"

type ComponentProps = Omit<CtaStoryblok, "_uid" | "component">

interface WrapperProps {
  variant: "light_blue" | "dark_blue" | "blumine"
}

const wrapperVariant: Record<WrapperProps["variant"], TwStyle> = {
  blumine: tw`bg-blue-blumine text-white border-b-8 border-[#144661]`,
  dark_blue: tw`text-white bg-blue-prussian`,
  light_blue: tw`bg-white shadow-md text-gray-2`,
}

const Title = tw(H2)`
  [&[data-text-size='smaller']]:(text-23 tablet:text-24 laptop:text-34)
  [&[data-text-variant='light_blue']]:(font-medium text-23 tablet:text-24 laptop:text-30)
`

const Description = tw(P)`max-w-2xl text-center`

const CtaWrapper = styled.div<WrapperProps>(() => [
  tw`
    w-full
    text-center
    flex
    flex-col
    items-center
    laptop:rounded-lg pb-4 px-4
    [&[data-horizontal-padding="true"]]:(
      laptop:w-1/2
      tablet:w-3/4
      w-[calc(100vw - 2.5rem)]
      rounded-lg
    )
  `,
  ({ variant }) => wrapperVariant[variant],
])

export const CtaComponent = (props: ComponentProps) => (
  <CtaWrapper data-horizontal-padding={props.horizontal_padding} variant={isUndefined(props.style)
    ? "dark_blue"
    : props.style}>
    <Title
      data-text-size={props.title_size}
      data-text-variant={props.style}
    >
      {props.title}
    </Title>
    <Description data-text-variant={props.style}>{props.subtitle}</Description>
    {props.buttons?.[0] && <ButtonGroup blok={props.buttons[0]} />}
  </CtaWrapper>
)

interface Props {
  readonly blok: CtaStoryblok
}

const Cta: React.FC<Props> = ({ blok }) => {
  const { _uid, component, ...blokWithoutSbConfig } = blok
  return (
    <SbEditable content={blok}>
      <CtaComponent {...blokWithoutSbConfig} />
    </SbEditable>
  )
}

export default Cta
