import React from "react"
import SbEditable from "storyblok-react"
import { BunnyEmbedStoryblok } from "../../../component-types-sb"
import useLocale from "../../hooks/useLocale"

interface Props {
  blok: BunnyEmbedStoryblok
}

export const BunnyVideo: React.FC<{ bunnyId: string }> = ({ bunnyId }) => {
  const { locale } = useLocale()
  if (!bunnyId) return <p tw="text-center text-30 text-red">No Bunny id</p>
  let iframeUrl = `https://iframe.mediadelivery.net/embed/215028/${bunnyId}?autoplay=false`

  if (locale === "nl")
    iframeUrl += "&captions=dut"

  if (locale === "de")
    iframeUrl += "&captions=ger"

  return (
    <div style={{ paddingTop: "56.25%", position: "relative" }} tw="w-full h-full rounded-xl overflow-hidden">
      {/* eslint-disable-next-line react/iframe-missing-sandbox */}
      <iframe
        src={iframeUrl}
        loading="lazy"
        style={{ border: "none" }}
        allowFullScreen={true}
        title="Bunny"
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        tw="absolute inset-0 w-full h-full"
      />
    </div>
  )
}

const BunnyEmbed: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <div tw="max-w-container w-full">
      <BunnyVideo bunnyId={blok.bunny_id} />
    </div>
  </SbEditable>
)

export default BunnyEmbed
