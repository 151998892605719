import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/pro-solid-svg-icons"
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faInstagram,
  faPinterestSquare,
} from "@fortawesome/free-brands-svg-icons"
import SbEditable from "storyblok-react"
import { useLocation } from "@reach/router"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import tw from "twin.macro"
import useLocale from "../../../hooks/useLocale"
import Image from "../../general/Image"
import useTranslatedLinks from "../../../contexts/TranslatedLinksContext"
import useStoryblok from "../../../hooks/useStoryblok"
import { FooterStoryblok } from "../../../../component-types-sb"
import LinkResolver from "../../general/Link"
import { changePreferredLang } from "../../../utils/changePreferredLang"
import locales from "../../../data/locales"
import { Richtext } from "../../general/Richtext"

const DescriptionWrapper = tw.div`
  mb-6
  whitespace-pre-line
  [maxWidth:300px]
  [p]:(text-15 leading-relaxed)
  [& svg]:(w-4! h-4)
`

const LinkWrapper = tw.div`
[&[data-long~="true"]]:(col-span-2)
font-light col-span-1 text-15
`

interface FooterData {
  stories: {
    nodes: {
      uuid: string
      lang: string
      content: string
      field_component: string
    }[]
  }
}

const Footer: React.FC = () => {
  const data: FooterData = useStaticQuery(graphql`
    query FooterQuery {
      stories: allStoryblokEntry(filter:{field_component:{eq:"footer"}}){
        nodes{
          uuid
          lang
          content
          field_component
        }
      }
    }
  `)
  const { preferredLang } = useLocale()
  // eslint-disable-next-line typescript-eslint/no-non-null-assertion
  const story = data.stories.nodes.find((x) => x.lang === preferredLang)!
  const location = useLocation()
  const { content } = useStoryblok(story, location) as { content: FooterStoryblok }
  const links = useTranslatedLinks()
  const icons = [
    { icon: faFacebookSquare, link: content.facebook, title: "Facebook" },
    { icon: faTwitterSquare, link: content.twitter, title: "Twitter" },
    { icon: faLinkedin, link: content.linkedIn, title: "LinkedIn" },
    { icon: faInstagram, link: content.instagram, title: "Instagram" },
    { icon: faPinterestSquare, link: content.pintrest, title: "Pintrest" },
  ]
  const socialInCategories = content.categories.some((category) => category.is_social)
  const gdprInCategories = content.categories.some((category) => category.is_gdpr)

  return <SbEditable content={content}>
    <footer tw="p-12 pb-6 bg-blue-midnight text-white">
      <div tw="grid grid-cols-1 desktop:(grid-cols-6) tablet:(grid-cols-2) gap-6 mx-auto">
        <div tw="col-span-1 tablet:col-span-2">
          <div tw="mb-5">
            <Link to={`/${preferredLang.replace("default", "")}`}>
              <Image image={content.logo} width="200px" fluid={200} />
            </Link>
          </div>
          <DescriptionWrapper>
            {content.description && <Richtext text={content.description as DocumentNode} />}
          </DescriptionWrapper>
          {!socialInCategories && <div tw="flex space-x-4 mb-6">
            {icons.map(({ icon, link, title }) => <a
              key={title}
              tw="text-20 hocus:text-blue"
              target="_blank"
              href={link}
              rel="noreferrer noopener"
            >
              <span tw="sr-only">{`WebinarGeek ${title} page`}</span>
              <FontAwesomeIcon icon={icon} />
            </a>)}
          </div>}

          <div tw="flex flex-row space-x-5 flex-wrap -ml-5">
            {content.badges?.map(({ image, url }) => <LinkResolver url={url} tw="ml-5" key={image.id}>
              <Image tw="w-[75px]" image={image} width="75px" />
            </LinkResolver>)}
          </div>
        </div>
        {content.categories.map((category) => <SbEditable content={category} key={category._uid}>
          <ul tw="flex-1 pr-4 col-span-1">
            <li tw="mb-3.5 text-18 font-medium">
              <LinkResolver url={category.url} styling="highlight">
                {category.title}
              </LinkResolver>
            </li>
            {category.links.map((link) => <SbEditable key={link._uid} content={link}>
              <li tw="mb-1.5 text-15 font-light">
                <LinkResolver url={link.url} styling="highlight">{link.text}</LinkResolver>
              </li>
            </SbEditable>)}
            {category.is_social && <>
              <li tw="mb-3.5 mt-5.75 text-18 font-medium">
                Social
              </li>
              <div tw="flex space-x-4 mb-6">
                {icons.map(({ icon, link, title }) => <a
                  key={title}
                  tw="text-20 hocus:text-blue"
                  target="_blank"
                  href={link}
                  rel="noreferrer noopener"
                >
                  <span tw="sr-only">{`WebinarGeek ${title} page`}</span>
                  <FontAwesomeIcon icon={icon} />
                </a>)}
              </div>
            </>
            }
            {category.is_gdpr && <div tw="pt-4"><Image image={content.gdpr_image} /></div>}
          </ul>
        </SbEditable>)}
        <div
          tw="flex flex-col space-x-0 space-y-1.5 items-start
            desktop:(flex-row space-x-2 items-center space-y-0 flex-wrap col-span-2) col-span-1 text-15"
        >
          <FontAwesomeIcon icon={faGlobe} tw="text-20 mr-2" />
          {locales.map(({ locale, name }) => <span key={name}>
            {preferredLang === locale
              ? <span tw="text-blue font-medium">{name}</span>
              : <Link to={links[locale]} tw="font-light hocus:text-blue" onClick={() => changePreferredLang(locale)}>
                <span>{name}</span>
              </Link>}
          </span>)}
        </div>
        {content.bottomLinks.map((item, i) => <SbEditable key={item._uid} content={item}>
          {(item.component === "link")
            ? <LinkWrapper key={item._uid} data-long={i === 0 && gdprInCategories}>
              <LinkResolver url={item.url} styling="highlight">
                {item.text}
              </LinkResolver>
            </LinkWrapper>
            : !gdprInCategories && <div key={item._uid} tw="pt-4 desktop:pt-0"><Image image={item.image} /></div>
          }
        </SbEditable>)}
      </div>
    </footer>
  </SbEditable>
}

export default Footer
