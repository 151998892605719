import React from "react"
import SbEditable from "storyblok-react"
import { TrailButtonStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import LinkButton from "./LinkButton"

interface Props {
  blok: TrailButtonStoryblok
}

const TrailButton: React.FC<Props> = ({ blok }) => {
  const { trail_button } = useGlobalSettings()

  if (!trail_button || trail_button.length !== 1)
    return <span>Trail button not found, update it in global settings</span>

  return (
    <SbEditable content={blok}>
      <LinkButton button={trail_button[0]} />
    </SbEditable>
  )
}

export default TrailButton
